<template>
  <div class="trace container">

    <Head></Head>
    <main>
      <div class="banner" style="background: #00BCD4;">
        <div class="wrap">
          <div class="txt">
             <h1>溯源系统</h1>
           
            <div class="subtitle">从工厂→省办→地办→县办→终端，真实记录产品流向。</div>
            <div>· 实现一站式防伪、防窜货，全链条数据跟踪。</div>

            <div>· 同时达到渠道管控，有效的防止、控制窜货行为，从而达到保护市场、维护省/地/县办利益，避免恶性竞争。</div>

            <div>· 任务实时跟踪、统计，实现以销定需、以销定购等多种业务模式</div>
          </div>
          <div class="img">
            <img src="../assets/Class.svg">
          </div>
        </div>
      </div>
      <ContBolck v-for="(item,index) in cont.records" :key="index" :title="item.title" :sub-title="item.desc">
        <div style="text-align:center">
          <img :src="item.src" alt="">
        </div>
      </ContBolck>
    </main>
    <Foot></Foot>
  </div>
</template>

<script>
  import ContBolck from '../components/contBolck'
  import Foot from '../components/foot'
  import Head from '../components/head'
  export default {
    components: {
      ContBolck,
      Foot,
      Head
    },
    data() {
      return {
        cont: {
          records: [{
            title: '任务实时跟踪、统计',
            desc: '省办通过系统实时查看各层级销售出库情况，深入了解地办/县办入库、出库、退货、借货、还货等，实现以销定需、以销定购等多种业务模式。根据地/县办月（季/年）度销量，对销售暴增或长时间未有销量、库存不合理的地/县办进行跟进，了解详情，帮助地/县办解决困难。',
            src: require('../assets/track01.jpg')
          }, {
            title: '实时掌控库存',
            desc: '省办实时掌控地办、县办及终端库存数，根据销量直接管控直营地办、县办安全库存数，避免库存积压，同时对促销活动时突增的销量灵活的调整库存。通过对当前库存、出入库数据的分析，保持合理的库存，及时配货、降低资金占用风险，避免库存积压。',
            src: require('../assets/track02.jpg')
          }, {
            title: '统计分析报表实时查询',
            desc: '综合数据报表查询——库存报表、进销存报表、出库/入库报表、终端月销报表，全面、及时、精准的掌握区域销售数据，根据销售数据科学的分析，掌控季度，甚至全年的一个货需情况。',
            src: require('../assets/track03.jpg')
          }]
        }
      }
    }
  }
</script>


<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    main {
      flex: 1;

      .banner {

        background: #2979FF;

        .wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 520px;
          line-height: 36px;
          color: #FFF;

          h1 {
            font-size: 36px;
            font-weight: bold;
          }

          .subtitle {
            font-size: 20px;
          }

          .img {
            width: 35%;
            overflow: hidden;
            height: 100%;
            display: flex;
            align-items: flex-end;

            img {
              width: 100%;
            }
          }

        }
      }
    }
  }
</style>